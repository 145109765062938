import Styled from "styled-components";


export const ButtonOutlined = Styled.button`
  padding :  10px 20px 10px 20px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  background: transparent;
  color: #1A73E8;
  white-space: nowrap;
  &:hover{
    background: #F1F3F4;
    color: #174EA6;
    border: 1px solid #174EA6;
  }
`;

export const ButtonLink = Styled.button`
  padding :  10px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #1A73E8;
  white-space: nowrap;
  outline: none;
  &:hover{
    outline: none;
    color: #174EA6;
  }

`;

export const ButtonFilled = Styled.button`
  padding :  10px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  background: #1A73E8;
  color: #fff;
  white-space: nowrap;
  &:hover{
    background: #174EA6;
  }

`;
