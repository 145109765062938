import React, {useEffect} from "react";
import Wrapper from "./style";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-bootstrap";

function Offcanvas({ handleClose, Item }) {
  const iconStyle = { color: "#474747", cursor: "pointer" };
  useEffect(() => {

    const body = document.body;
    const overflowStyle = body.style.overflow;

    if (Item) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = overflowStyle;
    }

    return () => {
      body.style.overflow = overflowStyle;
    };
  }, [Item]);
  return (
    <Wrapper>
      <div className="float-right">
        <CloseIcon onClick={handleClose} style={iconStyle} />
      </div>
      <div className="main-container">
        <div className="left-container">
          <span className="title">{Item?.title}</span>
          <hr />
          <br />
          <Row>
            {Item?.subItems.map((val) => {
              return (
                <Col>
                  <span className="subTitle">{val?.title}</span>
                  <div className="list-items">
                    {val?.subItems.map((list) => {
                      return (
                        <>
                          {list?.logo && <img src={list?.logo} alt="icon" />}
                          <div className="items">
                            <figcaption className="heading">
                              {list?.title}
                            </figcaption>
                            <span>{list?.subTitle}</span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <div className="right-container">
          <div className="list">
            {Item?.subNavigators.map((list) => {
              return <h6>{list?.title}</h6>;
            })}
          </div>
        </div>
      </div>
      <div className="overlay" onClick={handleClose}></div>
    </Wrapper>
  );
}

export default Offcanvas;
