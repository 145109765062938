import React from 'react';
import Wrapper from './style';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { ButtonLink } from '../../../../Reuseable/UIButtons';

function CardContainer({ content }) {

    return (
        <Wrapper>
            <div className='container main-container'>
                <div className='first-card'>
                    <span>{content?.tag}</span>
                    <h3>{content?.title}</h3>
                    <p>{content?.summary}</p>
                </div>
                {content?.cards?.map((card) => {
                    return (
                        <Card sx={{ maxWidth: 345 }} className='card-item'>
                            <CardMedia
                                sx={{ height: 160 }}
                                image={card?.image}
                                title="green iguana"
                            />
                            <CardContent>
                            <span>{card?.tag}</span>
                             <p>{card?.title}</p>
                        
                            </CardContent>
                            <CardActions>
                               <ButtonLink>{card?.ButtonName}</ButtonLink>
                            </CardActions>
                        </Card>
                    )
                })}

            </div>
        </Wrapper>
    )
}

export default CardContainer