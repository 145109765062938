import React from 'react'
import Wrapper from './style'

function Footer({ footer }) {
    return (
        <Wrapper>
            <div className='container'>
                <div className='socialmedia'>
                    <p>{footer?.socialMediaQoutes}</p>
                    <div className='icons'>
                        {footer?.socialMediaIcons.map((icon) => {
                            return icon?.icon
                        })}
                    </div>
                </div>
                <div className='sites'>
                    {footer?.listSites.map((val) => {
                        return (
                            <div className='list-container'>
                                <h6>{val?.title}</h6>
                                {val?.subItems.map((item) => {
                                    return (
                                        <li>{item?.name}</li>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className='last-details'>
                    <p>{footer?.siteTitle}</p>
                </div>

            </div>
        </Wrapper>
    )
}

export default Footer