import React, {useEffect} from "react";
import Wrapper from "./style";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "react-bootstrap";
import {
  ButtonOutlined,
  ButtonLink,
  ButtonFilled,
} from "../../../../Reuseable/UIButtons";
import UIMobileAccordion from "../../../../Reuseable/UIMobileAccordion";

function MobileOffCanvas({ handleClose, Item }) {
  console.log("Item--", Item)
  const iconStyle = { color: "#474747", cursor: "pointer" };
 
  return (
    <Wrapper>
      <div className="float-right">
        <CloseIcon onClick={handleClose} style={iconStyle} />
      </div>
      <div className="main-container">
        <div>
            <div className="nav-item">
              {Item?.MenuList.map((val, index) => {
                return (
                  <UIMobileAccordion key={index} value={val} panelKey={`panel-${index}`} />
                );
              })}
            </div>
        </div>
        <hr/>
      <div className="header-btns">
            {Item?.ActionButtonList.map((val) => {
              return (
                <>
                  {val?.type === "outlined" && (
                    <ButtonOutlined>{val?.name}</ButtonOutlined>
                  )}
                  {val?.type === "link" && <ButtonLink>{val?.name}</ButtonLink>}
                  {val?.type === "filled" && (
                    <ButtonFilled>{val?.name}</ButtonFilled>
                  )}
                </>
              );
            })}
          </div>
       </div>
      {/* <div className="overlay" onClick={handleClose}></div> */}
    </Wrapper>
  );
}

export default MobileOffCanvas;
