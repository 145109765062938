import React, { useState, useEffect } from "react";
import HeaderWrap from "./style";
import {
  ButtonOutlined,
  ButtonLink,
  ButtonFilled,
} from "../../../../Reuseable/UIButtons";
import Offcanvas from "../Offcanvas";
import { Modal } from "react-bootstrap";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import MobileOffCanvas from "../MobileOffCanvas";

function Header({ header }) {
  console.log("header --->", header);
  const [show, setShow] = useState(false);
  const [subItem, setSubItem] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [mobileCanvas, setMobileCanvas] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    if (val?.subItems.length > 0) {
      setShow(true);
      setSubItem(val);
    }
  }

  const handleMobileOffcanvas = () => {
    setMobileCanvas(!mobileCanvas)
  }

  return (
    <HeaderWrap>
      <div className="header-container">
        <div className="header-items">
          <div className="logo">
            <img src={header?.Logo} alt="logo" />
          </div>
          {!isMobile &&
            <div className="nav-item">
              {header?.MenuList.map((val) => {
                return (
                  <li onClick={() => handleShow(val)} key={val?.Id}>
                    {val?.title}
                    {val?.subItems.length > 0 && <KeyboardArrowDownIcon className="icon" />}
                  </li>
                );
              })}
            </div>
          }
        </div>
        {!isMobile &&
          <div className="header-btns">
            {header?.ActionButtonList.map((val) => {
              return (
                <>
                  {val?.type === "outlined" && (
                    <ButtonOutlined>{val?.name}</ButtonOutlined>
                  )}
                  {val?.type === "link" && <ButtonLink>{val?.name}</ButtonLink>}
                  {val?.type === "filled" && (
                    <ButtonFilled>{val?.name}</ButtonFilled>
                  )}
                </>
              );
            })}
          </div>
        }
        {isMobile && (
          <div className="menu-button" >
            <MenuIcon onClick={handleMobileOffcanvas} />
          </div>
        )}
      </div>

      {show && <Offcanvas handleClose={handleClose} Item={subItem} />}
      {mobileCanvas && <MobileOffCanvas handleClose={handleMobileOffcanvas} Item={header}/>}
    </HeaderWrap>
  );
}

export default Header;
