import React from "react";
import Header from "./Component/Header";
import { WorkSpaceJson } from "../../../JsonFormat/WorkSpaceJson"
import IntroDetails from "./Component/IntroDetails";
import CardContainer from "./Component/CardContainer";
import Questions from "./Component/Questions";
import TrailInfo from "./Component/TrailInfo";
import Footer from "./Component/Footer";
import Banner from "./Component/Banner";


function WorkSpace() {
  return (
    <div>
      <Header header={WorkSpaceJson.header}/>
      <Banner/>
      <IntroDetails content={WorkSpaceJson.introduction}/>
      <CardContainer content={WorkSpaceJson.Card}/>
      <Questions content={WorkSpaceJson.questions}/>
      <TrailInfo content={WorkSpaceJson.trailInfo}/>
      <Footer footer={WorkSpaceJson.footer}/>
    </div>
  );
}

export default WorkSpace;
