import React from "react";
import store from "./slice/store/configureStore";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import Main from "./Components/Pages/MainPage";


function App() {
  const headingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  };
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />

        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
