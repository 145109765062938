import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Styled from "styled-components";

const Accordion = Styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
   
    textTransform: "capitalize",
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionDetails = Styled(MuiAccordionDetails)(({ theme }) => ({
    padding: "20px",
    transition: "height 2s ease-in-out",
}));

const AccordionSummary = Styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#000" }} />}
        {...props}
    />
))(({ theme }) => ({

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        color: "#000",
    },
}));

function UIMobileAccordion({ value, panelKey }) {
    console.log("UIMobileAccordion--->", value);
    const [expanded, setExpanded] = React.useState(false);
  
    const handleChange = () => {
      setExpanded(!expanded);
    };
  
    return (
      <div>
        {value?.subItems?.length > 0 && (
          <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary aria-controls={`${panelKey}-content`} id={`${panelKey}-header`}>
              <span style={{cursor: "pointer" }}>{value?.title}</span>
            </AccordionSummary>
            <AccordionDetails>
              {value?.subItems?.map((subItem, index) => (
                <UIMobileAccordion key={index} value={subItem} panelKey={`${panelKey}-${index}`} />
              ))}
            </AccordionDetails>
          </Accordion>
        )}
        {(!value?.subItems || value?.subItems.length === 0) && (!value?.listItems || value?.listItems.length === 0) && (
          <div  className='ms-2' style={{marginTop: "20px"}}>
            <span style={{ textTransform: 'capitalize', cursor: "pointer"}}>{value?.title}</span>
         </div>
        )}
      </div>
    );
  }

export default UIMobileAccordion;
