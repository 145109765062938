import Styled from "styled-components";

const Wrapper = Styled.div`
    position: relative;
    background: white; 
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #DFE1E5;
   box-shadow: -1px 2px 5px 0px #efefef;
    .float-right{
        position: absolute;
        right : 20px;
        top: 10px;
    }
    .main-container{
    .header-btns{
       display: flex;
       justify-content: center;
       gap: 20px;
       padding: 10px;
     }
    }

   
`;

export default Wrapper