import React from 'react'
import Wrapper from './style'
import { ButtonFilled } from '../../../../Reuseable/UIButtons'

function TrailInfo({ content }) {
  return (
    <Wrapper>
        <div className='start'></div>
        <div className='circle1'></div>
        <h1>{content?.title}</h1>
        <ButtonFilled>{content?.buttonName}</ButtonFilled>
        <div className='end'></div>
        <div className='circle2'></div>
    </Wrapper>
  )
}

export default TrailInfo