import React from 'react'
import { WorkSpaceJson } from '../../../../../JsonFormat/WorkSpaceJson'
import Wrapper from './style'

function Banner() {
  return (
    <Wrapper>
        <div className='banner'>
           <span >{WorkSpaceJson?.banner}</span>
        </div> 
    </Wrapper>
  )
}

export default Banner