import Styled from "styled-components";

const Wrapper = Styled.div`
   .main-container{
     padding: 3%;
     display: flex;
     align-items: center;
     justify-content: space-evenly;
     gap: 20px;
     @media (max-width: 1000px) {
      flex-direction: column
    }
     .content{
        margin-top: 20px;
        .products{
            img{
                margin-left: -10px;
                max-width: 250px;
            }
        }
        h1{
            font-size: 52px;
            font-weight: bolder;
            @media (max-width: 1000px) {
              font-size: 40px;
            }
        }
        p{
            margin-top: 20px;
            font-size: 20px;
            max-width: 500px;
        }
        @media (max-width: 1000px) {
          text-align: center;
        }
     }
   }
   .video-content{
     margin-top: 20px;
     display: grid;
     place-items: center;
     h2{
        font-size: 36px;
        max-width: 550px;
        text-align: center;
        font-weight: bold;
        @media (max-width: 1000px) {
          font-size: 25px;
          width: 100%;
        }
     }
     video{
        margin: 5%;
        /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);  */
        border-radius: 10px;
        width: 640px;
        height: 360px;
        @media (max-width: 1000px) {
          width: 340px;
          height: 240px;
        }
     }
   }
  
`;

export default Wrapper