import React from 'react'
import Wrapper from './style'
import { ButtonFilled } from '../../../../Reuseable/UIButtons'
import { Col, Row } from "react-bootstrap";

function IntroDetails({ content }) {
    return (
        <Wrapper>
            <div className='container main-container'>
                <div className='content'>
                    <div className='products'>
                        <img alt="products" src={content?.ProductsIcon} />
                    </div>
                    <h1>
                        {content?.Details?.heading}
                    </h1>
                    <p>
                        {content?.Details?.summary}
                    </p>
                    <ButtonFilled>{content?.Details?.buttonName}</ButtonFilled>
                </div>
                <div className='svg'>
                    <img alt="image" width="100%" src={content?.Svg} />
                </div>
            </div>
            <div className='container video-content'>
                <h2>{content?.VideoHeading}</h2>
                <video autoPlay loop muted>
                    <source src={content?.Video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Wrapper>
    )
}

export default IntroDetails