import Styled from "styled-components";

const HeaderWrap = Styled.div`
   width: 100%;
   height: 60px;
   min-height: 50px;
   background: white;
   border-bottom: 1px solid #DFE1E5;
   box-shadow: -1px 2px 5px 0px #efefef;
   position: sticky;
   z-index: 1000;
   top: 0;
   .header-container{
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 100%;
     padding-left : 30px;
     .header-items{
       display: flex;
       align-items: center;
       gap: 20px;
        .logo{
          img{
             max-width: 200px;
          }
        }
        .nav-item{
           margin-left :5%;
           padding-left: 10px;
           padding-top: 5px;
           display: flex;
           gap: 30px;
           li {
            position: relative;
             list-style: none;
             cursor: pointer;
             color: #474747;
             .icon{
               position: absolute;
               font-size: 16px;
               margin-left: 20px;
               display: none;
             }
             &:hover {
               color: #000;
               .icon{
                display: block;
               }
             }
           }
        }
     }
     .header-btns{
       display: flex;
       gap: 20px;
       padding: 10px;
     }
     .menu-button{
      color: #474747;
      padding: 10px;
      cursor: pointer;
     }
   }
`;

export default HeaderWrap;
