import Styled from "styled-components";

const Wrapper = Styled.div`
    margin: 2%;
    background: #F8F8F8;
   .main-container{
    padding: 3%;
    display: flex;
    justify-content: space-around;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    @media (max-width: 1000px) {
      gap: 20px;
    }

    .first-card{
        max-width: 345px;
        h3{
            font-size: 36px;
        }
    }
    .card-item{
      border-bottom: 1px solid #DFE1E5;
      box-shadow: -1px 2px 5px 0px #E3E5E8;
      p{
        margin-top: 2%;
        font-size: 18px;
      }
      span{
        font-size: 12px;
        text-transform: uppercase;
      }
    }
   }
`;

export default Wrapper