import React, { useState } from 'react'
import Wrapper from './style'
import UIAccordion from '../../../../Reuseable/UIAccordion'
import { ButtonLink, ButtonOutlined } from '../../../../Reuseable/UIButtons'

function Questions({ content }) {
    const [btnName, setBtnName] = useState("Expand all");
    const [isExpand, setIsExpand] = useState(false);
    const handleclick = (isVal) => {
        setIsExpand(!isExpand)
        setBtnName(isVal ? "Collapse all" : "Expand all")
    }
    return (
        <Wrapper>
            <div className='container main-container'>
                <h3>{content?.title}</h3>
                <div className='content'>
                    <div className='left-container'>
                        <div className='btn1'>
                            <ButtonLink onClick={() => handleclick(!isExpand)}>{btnName}</ButtonLink>
                        </div>
                        <div>
                            {content?.list.map((val) => {
                                return (
                                    <UIAccordion value={val} isAllExpand={isExpand} />
                                )
                            })}
                        </div>

                    </div>
                    <div className='right-container'>
                        {content?.containerList.map((val) => {
                            return (
                                <div className='card-item'>
                                    <h6>{val?.title}</h6>
                                    <p>{val?.summary}</p>
                                    <ButtonOutlined>{val?.buttonName}</ButtonOutlined>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Questions