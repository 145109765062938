import Styled from "styled-components";

const Wrapper = Styled.div`
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center; 
    background-color: #3367D6 !important;
    padding: 10px;
    .banner{
        span{
           color: #fff;    
        }
    }
   
`;

export default Wrapper