import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import WorkSpace from "../WorkSpace";

function Main() {
  return (
    <div className="App">
      <WorkSpace />
    </div>
  );
}

export default Main;
