import Styled from "styled-components";

const Wrapper = Styled.div`
  .main-container{
    padding: 3%;
    .content{
        margin-top: 5%;
        display: flex;
        justify-content: space-between;
        gap: 30px;
        @media (max-width: 1000px) {
            flex-direction: column;
        }
    }
    h3{
        text-align: center;
        font-size: 36px;
    }
    .left-container{
        padding-right: 30px;
        .btn1{
          display: grid;
          place-items: end;
          margin-bottom: 20px;
        }
    }
    .right-container{     
        .card-item{
            padding: 30px;
            min-width: 340px;
            border: 1px solid #E0E0E0;
            margin: 20px;
            p{
                font-size: 14px;
                color: #212121;
            }
            h6{
                font-weight: bold;
            }
        }
    }
  }
`;

export default Wrapper